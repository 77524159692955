import { useEffect, useRef, useState, useMemo } from "react"
import StoryblokClient from "storyblok-js-client"
import getUniquePrimitive from "helpers/getUniquePrimitive"
import { STORYBLOK_LANGUAGES_ARRAY } from "consts"

import STORYBLOK_CONFIG from "../../config/storyblokConfig"

const ENTER_EDIT_MODE_EVENT_TIMEOUT = 3000

const Storyblok = new StoryblokClient({
  accessToken: STORYBLOK_CONFIG.accessToken,
  cache: {
    clear: "auto",
    type: "memory",
  },
})

function useStoryblokEditorMetadataFromURL() {
  return useMemo(() => {
    if (typeof window === "undefined") {
      return []
    }

    const path = new URLSearchParams(window.location.search).get("path")

    const storyblokLanguage =
      STORYBLOK_LANGUAGES_ARRAY.find(language =>
        path.includes(`${language}/`)
      ) || null

    const isLayoutEditing = !!STORYBLOK_LANGUAGES_ARRAY.find(language => {
      if (language === "default") {
        return path === "layout"
      } else {
        return path === `${language}/layout`
      }
    })

    const storyId = new URLSearchParams(window.location.search).get(
      "_storyblok"
    )

    return [storyblokLanguage, isLayoutEditing, storyId]
  }, [])
}

export default function useStoryblokEditor() {
  const [, setUniquePrimitive] = useState(null)

  const story = useRef(null)
  const layout = useRef(null)
  const prevBannerEnabled = useRef(null)
  const key = useRef(null)

  const [
    storyblokLanguage,
    isLayoutEditing,
    storyId,
  ] = useStoryblokEditorMetadataFromURL()

  function triggerRerender() {
    setUniquePrimitive(getUniquePrimitive())
  }

  function handleInput(event) {
    if (event.story.content._uid === story.current.content._uid) {
      // useSessionStorage in useBanner may return early based on banner enabled state
      // remount the page in case banner state changes to avoid error between re-renders
      if (prevBannerEnabled.current !== event.story.content.banner_enabled) {
        key.current = getUniquePrimitive()
        prevBannerEnabled.current = event.story.content.banner_enabled
      }
      story.current = {
        ...event.story,
        // response with story does not contain language
        // set it explicitly instead
        lang: storyblokLanguage,
      }
      triggerRerender()
    }
  }

  function handleLayoutInput(event) {
    if (event.story.content._uid === story.current.content._uid) {
      layout.current = {
        ...event.story,
        // response with story does not contain language
        // set it explicitly instead
        lang: storyblokLanguage,
      }
      triggerRerender()
    }
  }

  function handleEnterEditMode(event) {
    const language = storyblokLanguage ? `?language=${storyblokLanguage}` : ""
    Storyblok.get(`cdn/stories/${event.storyId}${language}`, {
      version: "draft",
      resolve_relations: [],
    })
      .then(({ data }) => {
        if (data.story) {
          story.current = { ...data.story, lang: storyblokLanguage }
          triggerRerender()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    // adds the events for updating the visual editor
    // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
    function initStoryblokEventListeners() {
      const { StoryblokBridge } = window
      if (typeof StoryblokBridge !== "undefined") {
        // initialize the bridge
        const storyblokInstance = new StoryblokBridge()

        // reload page on save or publish event in the Visual Editor
        storyblokInstance.on(["change", "published"], () =>
          window.location.reload(true)
        )

        if (!isLayoutEditing) {
          storyblokInstance.on(["input"], event => {
            handleInput(event)
          })
        } else {
          storyblokInstance.on(["input"], event => {
            handleLayoutInput(event)
          })
        }

        // loading the draft version on initial enter of editor
        storyblokInstance.on(["enterEditmode"], event => {
          handleEnterEditMode(event)
        })

        // previewing in a new window where enterEditmode event is not being fired
        setTimeout(() => {
          if (!story.current) {
            handleEnterEditMode({ storyId })
          }
        }, ENTER_EDIT_MODE_EVENT_TIMEOUT)
      }
    }

    // appends the bridge script tag to the document
    // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
    function addStoryblokBridge() {
      // check if the script is already present
      const existingScript = document.getElementById("storyblokBridge")
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
        script.id = "storyblokBridge"
        script.onload = () => {
          initStoryblokEventListeners()
        }

        document.body.appendChild(script)
      } else {
        initStoryblokEventListeners()
      }
    }

    // retrieves page layout
    function fetchLayout() {
      const language = storyblokLanguage ? `?language=${storyblokLanguage}` : ""
      Storyblok.get(`cdn/stories/layout${language}`, {
        version: "draft",
        resolve_relations: ["footer", "navigationBar"],
      })
        .then(({ data }) => {
          if (data.story) {
            layout.current = {
              ...data.story,
              lang: storyblokLanguage,
            }
            triggerRerender()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    // first load the bridge, then initialize the event listeners
    addStoryblokBridge()
    // get footer and navigation bar
    fetchLayout()
  }, [])

  return [story.current?.content, layout.current?.content, key.current]
}
