import React, { useMemo } from "react"
import PropTypes from "prop-types"
import SEO from "./SEO"
import { getSeoProps } from "./SEO/helpers"
import Layout from "./Layout"
import { getLayoutProps } from "./Layout/helpers"

export default function Page({
  story,
  layout,
  children,
  slug,
  seoProps,
  layoutProps,
  langLinks,
}) {
  const [baseSeoProps, baseLayoutProps] = useMemo(
    () => [getSeoProps(story, slug), getLayoutProps(story, layout, slug)],
    [story, layout, slug]
  )

  return (
    <>
      <SEO {...baseSeoProps} {...seoProps} langLinks={langLinks} />
      <Layout {...baseLayoutProps} {...layoutProps} langLinks={langLinks}>
        {children}
      </Layout>
    </>
  )
}

Page.propTypes = {
  story: PropTypes.object.isRequired,
  layout: PropTypes.shape({
    footer: PropTypes.array.isRequired,
    navigationBar: PropTypes.array.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  slug: PropTypes.string.isRequired,
  langLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
}
