import getPathFromSlug from "helpers/getPathFromSlug"

function getSeoCover(seo_cover) {
  if (typeof seo_cover === "object") {
    return seo_cover.filename
  } else if (typeof seo_cover === "string") {
    return seo_cover
  } else {
    return null
  }
}

export function getSeoProps(story, slug) {
  const validSlug = typeof slug === "string"
  if (!validSlug) {
    throw new Error(`Invalid slug provided (story: ${JSON.stringify(story)})`)
  }

  if (typeof story !== "object") {
    throw new Error(`Unable to derive SEO props for page with slug ${slug}`)
  }

  const validSeo = !!story.seo_title
  if (!validSeo) {
    console.warn(`Page with slug ${slug} has missing title`)
  }

  const seo = {
    title: story.seo_title,
    description: story.seo_description || null,
    cover: getSeoCover(story.seo_cover),
    noindex: !!story.meta_noindex,
  }

  return {
    data: seo,
    path: getPathFromSlug(slug),
  }
}
