import React from "react"
import SbEditable from "storyblok-react"
import Page from "components/_common/Page"
import withNoIndex from "components/_common/NoIndex/withNoIndex"
import { CONTENT_TYPES, LANGUAGES_ARRAY, ROUTES } from "consts"
import renderEditorBloks from "helpers/renderEditorBloks"
import useStoryblokEditor from "hooks/useStoryblokEditor"

const EditorPage = () => {
  const [story, layout, key] = useStoryblokEditor()

  if (!story || !layout) {
    return null
  }

  return (
    <SbEditable content={story}>
      <Page
        story={story}
        layout={layout}
        slug={ROUTES.EDITOR_PRESLASH}
        layoutProps={{
          whiteBackground: story.component === CONTENT_TYPES.BLOG_POST,
        }}
        langLinks={LANGUAGES_ARRAY}
        key={key}
      >
        {renderEditorBloks(story)}
      </Page>
    </SbEditable>
  )
}

export default withNoIndex(EditorPage)
