import getPathFromSlug from "helpers/getPathFromSlug"

export function getLayoutProps(story, layout, slug) {
  const validSlug = typeof slug === "string"
  if (!validSlug) {
    throw new Error(`Invalid slug provided (story: ${JSON.stringify(story)})`)
  }

  if (typeof story !== "object" || typeof layout !== "object") {
    throw new Error(`Unable to derive layout props for page with slug ${slug}`)
  }

  const validLayout =
    !Array.isArray(layout.footer) || !Array.isArray(layout.navigation)
  if (!validLayout) {
    throw new Error(
      `Invalid layout components provided on page with slug ${slug}`
    )
  }

  const validBanner =
    story.banner_text &&
    story.banner_link_text &&
    typeof story.banner_link_url === "object" &&
    story.banner_link_url?.cached_url
  const banner = validBanner
    ? {
        enabled: !!story.banner_enabled,
        text: story.banner_text,
        link_text: story.banner_link_text,
        link_url: story.banner_link_url,
        visible_during_pagination: !!story.banner_visible_during_pagination,
      }
    : null

  const navigationStyles = {
    variant: story.navigation_variant || "dark",
    version: story.navigation_version || "fixed",
  }

  return {
    banner,
    footerBlok: layout.footer[0] || null,
    navigationBlok: layout.navigationBar[0]
      ? { ...layout.navigationBar[0], ...navigationStyles }
      : null,
    slug,
    path: getPathFromSlug(slug),
  }
}
