import React from "react";
import NoIndex from "./NoIndex"

export default function withNoIndex(Component) {
  return (props) => (
    <>
      <Component {...props} />
      <NoIndex />
    </>
  );
}
