module.exports = {
  // TODO: Move Storyblok access token to .env (do not forget about MH deployment pipeline)
  accessToken: "AO0MMnbUgtxlxOnMcBViIQtt",
  homeSlug: "home",
  resolveRelations: ["blog_post.categories", "blog_post.related_posts"],
  version:
    process.env.NODE_ENV === "development" || process.env.GATSBY_CLOUD
      ? "draft"
      : "published",
}
